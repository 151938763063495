<div class="text-center">
	<img src="assets/img/masszi_cim.png" style="width:30%">
</div>

<div class="row">
  <div class="col-sm-12 col-md-4 p-3 mx-auto">
    <img src="assets/img/masszi_kuld_studio_02.jpg" alt="Studió" style="width:100%">
  </div>
  <div class="col-sm-12 col-md-4 p-3 mx-auto">
    <img src="assets/img/masszi_profil_b.png" alt="Masszi Géza" style="width:100%">
  </div>
  <div class="col-sm-12 col-md-4 p-3 mx-auto">
    <img src="assets/img/masszi_kuld_01_papir.png" alt="Oklevél" style="width:100%">
  </div>
</div>

<br>
<div class="text-center">
	<img src="assets/img/terkep.png" style="width:30%">
</div>
