export class Szolgaltatas {
    public kateg: string;
    public nev: string;
    public artol: number;
    public arig: number;
    public megj: string;
  
    constructor(kateg: string,nev: string, artol: number, arig: number, megj: string) {
      this.kateg = kateg;
      this.nev = nev;
      this.artol = artol;
      this.arig = arig;
      this.megj = megj;
     }  
  }