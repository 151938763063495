import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kepek',
  templateUrl: './kepek.component.html',
  styleUrls: ['./kepek.component.css']
})
export class KepekComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
