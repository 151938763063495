<div class="container">
    <div class="row">
        <div class="col-12 col-md-6">
            <h1>Nyitvatartás</h1>
            <ul>
                <li>Hétfő: 09-17</li>
                <li>Kedd: 09-17</li>
                <li>Szerda: 09-17</li>
                <li>Csütörtök: 09-17</li>
                <li>Péntek: 09-17</li>
                <li>Szombat: ZÁRVA</li>
                <li>Vasárnap: ZÁRVA</li>
            </ul>

        </div>
    </div>
</div>
