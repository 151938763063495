import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-szolg-elem',
  templateUrl: './szolg-elem.component.html',
  styleUrls: ['./szolg-elem.component.css']
})
export class SzolgElemComponent implements OnInit {


  ngOnInit(): void {
  }

}
