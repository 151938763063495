<div class="container">
    <div class="row">
        <div class="col-sx-12 text-center">
            <p class="lead">
                Szabóságunk 1997 óta működik, számos törzsvendéggel büszkélkedhetünk.
            </p>
            <p class="lead">
                Igazítást, javítást, teljes ruhák készítését is szívesen vállaljuk. Tekintse meg szolgáltatásink listáját az oldalon!
                Bármilyen szabászati kérésével forduljon hozzánk bizalommal!
            </p>            
        </div>
        <div class="col-sx-12 col-md-6">
            
        </div>        
    </div>
</div>
