import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private metaService:Meta) { }

  ngOnInit(): void {
    this.metaService.addTag( { name:'description',content:"1997 óta működik szabóságunk. Nagy tapasztalattal rendelkezünk! Szolgáltatásunk széles körű. Nadrág felhajtástól a teljes őltöny méretre igazításig, kosztüm igazításáig."});
    this.metaService.addTag( { name:'keywords',content:"szabó, szabóság, szabászat, varrónő, varroda, varrás, stoppol, stoppolás, igazítás, öltönykészítés, tömés, töm, slicc, zipzár, cipzár, szűkítés, méretre, méret, felhajtás, koptatott, szakad, szakadás"});
  }

}
