import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SzolgElemComponent } from './szolg-elem/szolg-elem.component';
import { SzolgListaComponent } from './szolg-lista/szolg-lista.component';
import { HeaderComponent } from './header/header.component';
import { NyitvaComponent } from './nyitva/nyitva.component';
import { BemutatkozasComponent } from './bemutatkozas/bemutatkozas.component';
import { KepekComponent } from './kepek/kepek.component';

@NgModule({
  declarations: [
    AppComponent,
    SzolgElemComponent,
    SzolgListaComponent,
    HeaderComponent,
    NyitvaComponent,
    BemutatkozasComponent,
    KepekComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
