<div class="container-fluid">
    <h1>Astoria Varroda - Masszi21 Szabóság</h1>
    <div class="row">
        <div class="col-sx-12 col-md-6">

            <div class="alert alert-info">Cím: Budapest, Rákóczi út 9, az Astoriánál</div>
            

        </div>
    
        <div class="col-sx-12 col-md-6">

            
            <div class="alert alert-info">Hívjon nyitvatartás alatt! +36 30 232 7586</div>

        </div>    
    
    </div>
</div>

