<div class="container">
<h1>Szolgáltatás árlista</h1>
    <div class="col-md-6">
        <div 
            class="row shadow-lg"
            *ngFor = "let Szolgaltatas of Szolgaltatasok"
        >
            <h4 
                *ngIf="voltMar(Szolgaltatas.kateg)" 
                style="background-color: rgb(205, 205, 255);"
                class="m-2">
                {{ Szolgaltatas.kateg }}
            </h4>
            <h5>{{ Szolgaltatas.nev }}: {{ Szolgaltatas.artol }}
                <span *ngIf="Szolgaltatas.arig != 0">- {{ Szolgaltatas.arig }} ft</span>  
                <span *ngIf="Szolgaltatas.arig === 0">ft</span>  
                {{Szolgaltatas.megj }}</h5>           
        </div>
        <hr>
    </div>
</div>
