import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bemutatkozas',
  templateUrl: './bemutatkozas.component.html',
  styleUrls: ['./bemutatkozas.component.css']
})
export class BemutatkozasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
