import { Component, OnInit } from '@angular/core';
import { Szolgaltatas } from '../szolg-elem/szolg.model';

@Component({
  selector: 'app-szolg-lista',
  templateUrl: './szolg-lista.component.html',
  styleUrls: ['./szolg-lista.component.css']
})
export class SzolgListaComponent implements OnInit {
  aktKateg: string = '';
  
  Szolgaltatasok: Szolgaltatas[] = [
    new Szolgaltatas('Férfi zakó igazítás','Ujja felhajtás',7800,0,''),
    new Szolgaltatas('Férfi zakó igazítás','Oldal szűkítés két oldal szívvel',7800,0,''),
    new Szolgaltatas('Férfi zakó igazítás','Oldal szűkítés slicc nélkül',4800,0,''),
    new Szolgaltatas('Férfi zakó igazítás','Alja felhajtás',7800,0,''),
    new Szolgaltatas('Férfi zakó igazítás','Válla szűkítés',7800,9800,''),
    new Szolgaltatas('Nadrág igazítás','Alja felhajtás',2500,0,''),
    new Szolgaltatas('Nadrág igazítás','Derék szűkítés',3500,0,'-tól'),
    new Szolgaltatas('Nadrág igazítás','Szár szűkítse',2500,3500,''),
    new Szolgaltatas('Női blézer igazítás','Ujja felhajtás',2500,4800,''),
    new Szolgaltatas('Női blézer igazítás','Szűkítés két oldalt',4800,7800,''),
    new Szolgaltatas('Női blézer igazítás','Alja felhajtás',4800,0,''),
    new Szolgaltatas('Női blézer igazítás','Válla szűkítés',4800,0,'-tól'),
    new Szolgaltatas('Szoknya igazítás','Oldal szűkítés',3800,0,'-tól'),
    new Szolgaltatas('Szoknya igazítás','Alja felvarrás',2500,0,'-tól'),
    new Szolgaltatas('Farmer igazítás','Alja felhajtás',2500,0,''),
    new Szolgaltatas('Farmer igazítás','Eredeti koptatott rész vissza varrása',2500,0,''),
    new Szolgaltatas('Farmer igazítás','Háta derék szűkítés',3500,0,''),
    new Szolgaltatas('Farmer igazítás','Derék szűkítés két oldalt',4800,0,''),
    new Szolgaltatas('Farmer igazítás','Szár szűkítés',3500,0,'-tól'),
    new Szolgaltatas('Farmer igazítás','Farmer ülep javítás stoppolás',3500,0,''),
    new Szolgaltatas('Farmer igazítás','Egyéb stoppolás',1500,0,''),
    new Szolgaltatas('Farmer igazítás','Fém gomb csere, beütés',400,0,'/db'),
    new Szolgaltatas('Bélés cserék','Zakóba',9800,0,'+bélés'),
    new Szolgaltatas('Bélés cserék','Kabátba',9800,0,'+bélés'),
    new Szolgaltatas('Bélés cserék','Szoknyába',4800,0,'+bélés'),
    new Szolgaltatas('Zipp csere','Dzsekibe',5800,9800,''),
    new Szolgaltatas('Zipp csere','Melegítő felső',3800,0,'-tól'),
    new Szolgaltatas('Zipp csere','Nadrág',2500,0,''),
    new Szolgaltatas('Zipp csere','Szoknya',2500,0,''),
    new Szolgaltatas('Zipp csere','Farmer',2500,0,''),
    new Szolgaltatas('Zipp csere','Díszpárna',2500,0,''),
    new Szolgaltatas('Függöny varrás','Függöny varrás',400,0,'/m')
  ];

  voltMar(kategIn: string){

    if (this.aktKateg == '' ) {
      this.aktKateg = kategIn;
      return true;
    }
 
    if (this.aktKateg == kategIn ) {
      return false;
    }

    if (this.aktKateg != kategIn ) {
      this.aktKateg = kategIn;
      return true;
    }

  }

  constructor() { }

  ngOnInit(): void {
  }

}
